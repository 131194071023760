<template>
  <div class="news_detail">
    <el-page-header @back="goBack" content="行业动态"></el-page-header>
    <div class="diag_title">
      {{ industry.title }}
    </div>
    <div v-html="industry.content"></div>
    <div class="diag_time">{{ industry.created_at }}</div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data(){
    return{
      industry:{}
    }
  },
  mounted() {
    this.industry = this.$route.query
  },
  methods:{
    goBack(){
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.news_detail{
  padding: 20px 10px;
  font-size: 14px;
}
.diag_title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.diag_time {
  font-size: 13px;
  color: #8c939d;
  line-height: 28px;
}
</style>
